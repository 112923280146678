// eslint-disable-next-line import/no-extraneous-dependencies
import { Turkish } from 'flatpickr/dist/l10n/tr'

export default {
  user() {
    return JSON.parse(localStorage.getItem('userData'))
  },
  taxClaveOptions: {
    numeral: true,
    numeralPositiveOnly: true,
    prefix: '% ',
    rawValueTrimPrefix: true,
  },
  currencyClaveOptions() {
    return {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true,
      prefix: `${this.user().currency.symbol !== '' ? this.user().currency.symbol : this.user().currency.currency_code} `,
      rawValueTrimPrefix: true,
    }
  },
  flatPickrConfig: {
    enableTime: true, dateFormat: 'Y-m-d H:i', locale: Turkish, altFormat: 'j F Y l H:i', altInput: true,
  },
  locales: [

    {
      locale: 'en',
      // eslint-disable-next-line global-require
      img: require('@/assets/images/flags/en.png'),
      name: 'English',
    },

  ],
  dayOptions() {
    const days = []
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < 32; i++) {
      days.push(i)
    }
    return days
  },
  recurringPeriodOptions: [
    { value: 1, label: 'Monthly' },
    { value: 2, label: 'BiWeekly' },
    { value: 3, label: 'Weekly' },
    { value: 4, label: 'Daily' }],
  monthOptions: [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'Ağustos' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },

    { value: 12, label: 'December' },
  ],

  getBlogContentType(value, searchType = 'value') {
    let selectedType = null
    this.blogContentTypes.forEach(val => {
      if (searchType === 'value') {
        if (value === val.value) {
          selectedType = val
        }
      } else if (searchType === 'key') {
        if (value === val.key_name) {
          selectedType = val
        }
      }
    })
    return selectedType
  },
  getNotificationType(value) {
    let selectedType = null
    this.notificationWarningTypes.forEach(val => {
      if (value === val.value) {
        selectedType = val
      }
    })
    return selectedType
  },
  buildNotificationLink(item) {
    let link = null
    if (item.type === 1) {
      link = { name: 'notification-detail', params: { id: item.id } }
    }
    return link
  },
  getMonthName(monthNumber) {
    let selectedMonthName = ''
    this.monthOptions.forEach(val => {
      if (monthNumber === val.value) {
        selectedMonthName = val.label
      }
    })
    return selectedMonthName
  },
  // bytes cinsinden dosya boyutunu okunaklı hale getir
  toHumanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
      return `${bytes} B`
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp

    do {
      // eslint-disable-next-line no-param-reassign
      bytes /= thresh
      // eslint-disable-next-line no-plusplus
      ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return `${bytes.toFixed(dp)} ${units[u]}`
  },
  // date operations
  // currency formatter
  formatCurrency(price, digits = 2) {
    const dollarUSLocale = Intl.NumberFormat('en-CA', { minimumFractionDigits: digits, maximumFractionDigits: digits })
    return dollarUSLocale.format(price)
  },
  getGeneralYearsAndMonthList() {
    const d = new Date()
    const thisYear = d.getFullYear()
    const targetYear = thisYear - 2

    const yearMonthList = []

    // eslint-disable-next-line no-plusplus
    for (let yIndex = thisYear + 1; yIndex > targetYear; yIndex--) {
      // eslint-disable-next-line no-plusplus
      for (let mIndex = 12; mIndex > 1; mIndex--) {
        const obj = {
          year: yIndex,
          month: mIndex,
        }
        yearMonthList.push(obj)
      }
    }

    return yearMonthList
  },
}
